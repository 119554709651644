$primary: #ff5000;

$active-color: $primary;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "~@bootstrap-extended-style/bootstrap-extended-style/index";

@import "~@fortawesome/fontawesome-pro/scss/fontawesome";
@import "~@fortawesome/fontawesome-pro/scss/brands";
@import "~@fortawesome/fontawesome-pro/scss/light";
@import "~@fortawesome/fontawesome-pro/scss/regular";
@import "~@fortawesome/fontawesome-pro/scss/solid";
@import "~@fortawesome/fontawesome-pro/scss/duotone";
@import "~swiper/css";

@import "actie";
@import "black-friday";
@import "carousel";
@import "content";
@import "cookie-consent-banner";
@import "ems";
@import "font";
@import "footer";
@import "gridsense";
@import "homepage-cta";
@import "homepage-hero";
@import "homepage-cta";
@import "image-text";
@import "image";
@import "jumbotron-calltoaction";
@import "mid-calltoaction";
@import "navbar";
@import "offerte-widget";
@import "offerte";
@import "overzicht-cta";
@import "product-plans";
@import "product-showcase";
@import "reviews";
@import "subpage-banner";
@import "steps";
@import "support-widget";
@import "swiper";
@import "text-size";
@import "theme";
@import "we-moved";

.jumbotron-header {
  background: url(../img/solar-banner.jpg) center;
  background-size: cover;
}

$page-spacer-height: 152px;
$page-spacer-height-mobile: 80px;
.navbar-page-spacer {
  height: $page-spacer-height;

  @media (max-width: #{$small-display-size-width}px), (max-height: #{$small-display-size-height}px) {
    height: $page-spacer-height-mobile;
  }

  @media (max-height: #{$mobile-height-sticky-navbar}px) {
    height: $page-spacer-height-mobile;
  }

  &.no-small-navbar {
    height: $page-spacer-height - 26px;

    @media (max-width: #{$small-display-size-width}px), (max-height: #{$small-display-size-height}px) {
      height: $page-spacer-height-mobile;
    }

    @media (max-height: #{$mobile-height-sticky-navbar}px) {
      height: $page-spacer-height-mobile;
    }
  }
}

.container-blue-background {
  background-color: #dfedff;
  padding: 80px 0;
  width: 100%;
  height: 100%;
}

.container-white-background {
  background-color: white;
  padding: 80px 0;
  width: 100%;
  height: 100%;
  margin-bottom: 80px;
}

.container-orange-background {
  background-color: rgba($color: #FF7D00, $alpha: 0.3);
  padding: 80px 0;
  width: 100%;
  height: 100%;
}
